import React from "react";
import { SEO, MarkdownContent } from "@bluefin/components";
import { Grid, Segment, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class SpaServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.revivevt.com/spa-services/"}
        />
        <Grid
          className={"custom-page-spa-services custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"ljrkhqrgiaxzspid"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"wfdtpjdxolmafjlp"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "789d994a-eabd-47f6-a3e5-89bd9b203cf3",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"bhjsxiqxnlozjlgu"}
            style={{ padding: 16, background: "#424942" }}
            textAlign={"left"}
          >
            <Grid.Column
              className={"mwologpalgyqxtzq"}
              style={{ padding: 16 }}
              width={6}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__3eda476f-25e8-4f15-9399-5cc460c83ddb"}
                >
                  <Embed
                    url={
                      "https://player.vimeo.com/video/1014809295?background=1"
                    }
                    autoplay={true}
                    defaultActive={true}
                    defaultActive={true}
                    className={"spa-video"}
                  />
                </React.Fragment>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"shzqgmsnjpwsadih"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "b5c04602-e2f4-44ef-9826-843a2ad4e276",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"bzbbwrrvkpfcteyo"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"gmxavnlggsuoqrwh"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "4fbd1567-4d43-4842-9107-12aa83529d6d",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26551 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
